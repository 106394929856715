import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Grid, Typography, Box, Select, MenuItem, Chip, FormControl,
    Paper, Breadcrumbs, Link, TextField, InputAdornment, useMediaQuery,
    InputLabel, Checkbox, ListItemText, CircularProgress, IconButton
} from '@mui/material';
import { Home as HomeIcon, ChevronRight as ChevronRightIcon, GridView, ViewList } from '@mui/icons-material';
import ProductItemSkeleton from "../ProductSkeleton";
import ProductItem from "../ProductItem";
import {useTheme} from "../../../Provider/ThemeProvider";

export default function ProductGrid({
                                        items,
                                        isLoading,
                                        error,
                                        selectedFilters,
                                        handleFilterChange,
                                        handleStockStatusChange,
                                        selectedCategory,
                                        selectedSubCategory,
                                        categories,
                                        subCategories,
                                        category_Id,
                                        dataClassification,
                                        isClassificationLoading,
                                        classificationError,
                                        dataManufacturers,
                                        isManufacturersLoading,
                                        manufacturersError,
                                        setPage,
                                        generation,
                                        isGenerationLoading,
                                        generationError,
                                    }) {
    const [budget, setBudget] = useState('');
    const [selectedManufacturers, setSelectedManufacturers] = useState([]);
    const [selectedClassifications, setSelectedClassifications] = useState([]);
    const [selectedGeneration, setSelectedGeneration] = useState([]);
    const [viewMode, setViewMode] = useState('grid'); // New state for view mode
    const navigate = useNavigate();
    const location = useLocation();
    const { currentTheme, setTheme } = useTheme()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlManufacturers = searchParams.get('manufacturers');
        const urlClassifications = searchParams.get('classifications');
        const urlGeneration = searchParams.get('generations');

        if (urlManufacturers) {
            const manufacturers = urlManufacturers.split(',');
            setSelectedManufacturers(manufacturers);
            handleFilterChange("manufacturers", manufacturers);
        }

        if (urlClassifications) {
            const classifications = urlClassifications.split(',');
            setSelectedClassifications(classifications);
            handleFilterChange("classifications", classifications);
        }
        if (urlGeneration) {
            const generations = urlGeneration.split(',');
            setSelectedGeneration(generations);
            handleFilterChange("generations", generations);
        }

    }, [location.search, handleFilterChange]);

    const getCategoryName = (categoryId) => {
        const category = categories.find(cat => cat.id === categoryId);
        return category ? category.desc : '';
    };

    const getSubCategoryName = (subCategoryId) => {
        const subCategory = subCategories.find(subCat => subCat.id === subCategoryId);
        return subCategory ? subCategory.desc : '';
    };

    const handleBudgetInput = (event) => {
        const value = event.target.value;
        setBudget(value);
        if (value === '' || isNaN(Number(value))) {
            handleFilterChange("priceRange", [0, Infinity]);
        } else {
            handleFilterChange("priceRange", [0, Number(value)]);
        }
    };

    const handleManufacturerChange = (event) => {
        const value = event.target.value;
        setSelectedManufacturers(value);
        handleFilterChange("manufacturers", value);
        updateURL('manufacturers', value);
        setPage(1)
    };

    const handleClassificationChange = (event) => {
        const value = event.target.value;
        setSelectedClassifications(value);
        handleFilterChange("classifications", value);
        updateURL('classifications', value);
        setPage(1)
    };
    const handleGenerationChange = (event) => {
        const value = event.target.value;
        setSelectedGeneration(value);
        handleFilterChange("generations", value);
        updateURL('generations', value);
        setPage(1)
    };


    const updateURL = (param, value) => {
        const searchParams = new URLSearchParams(location.search);
        if (value.length > 0) {
            searchParams.set(param, value.join(','));
        } else {
            searchParams.delete(param);
        }
        navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true});
    };

    const filteredItems = items.filter(item => {
        if (selectedManufacturers.length > 0 && !selectedManufacturers.includes(item.manufacturer)) {
            return false;
        }
        if (selectedClassifications.length > 0 && !selectedClassifications.includes(item.classification)) {
            return false;
        }
        if (selectedGeneration.length > 0 &&!selectedGeneration.includes(item.generation)) {
            return false;
        }
        return true;
    });

    return (
        <Box >
            <Paper elevation={3} sx={{mb: 3, p: 2,  backgroundColor: (theme) =>
                    currentTheme === 'dark' ? theme.palette.background.paper : 'transparent',
                boxShadow:
                    currentTheme === 'dark'
                        ? '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                        : 'none'}} >
                <Breadcrumbs separator={<ChevronRightIcon fontSize="small"/>} aria-label="breadcrumb">
                    <Link
                        href="/"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                        }}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Home
                    </Link>

                    <Link
                        href={`/laptops`}
                        sx={{
                     textDecoration: 'none',
                        }}
                    >
                        <Typography color="text.primary">
                            Laptops
                        </Typography>
                    </Link>
                    {selectedSubCategory.length > 0 && (
                        <Typography color="text.primary">{getSubCategoryName(selectedSubCategory[0])}</Typography>
                    )}
                </Breadcrumbs>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: "'PT Serif', serif !important",
                            fontSize: '28px',
                            lineHeight: '31px'
                        }}
                    >
                        {selectedCategory.length > 0 ? getCategoryName(selectedCategory[0]) : 'Laptops'}
                    </Typography>
                    <Chip
                        label={`${filteredItems.length} items`}
                        color="primary"
                        variant="outlined"
                        sx={{fontWeight: 'bold', fontSize: '1rem'}}
                    />
                </Box>
            </Paper>

            <Paper elevation={3} sx={{mb: 3, p: 2,  backgroundColor: (theme) =>
                    currentTheme === 'dark' ? theme.palette.background.paper : 'transparent',
                boxShadow:
                    currentTheme === 'dark'
                        ? '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                        : 'none'}} >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="sort-by-label">Sort By</InputLabel>
                            <Select
                                labelId="sort-by-label"
                                id="sort-by"
                                value={selectedFilters.sortBy}
                                label="Sort By"
                                onChange={(e) => handleFilterChange("sortBy", e.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: (theme) =>
                                                currentTheme === 'dark' ? theme.palette.background.paper : 'white',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="popularity">Price</MenuItem>
                                <MenuItem value="priceAsc">Price: Low to High</MenuItem>
                                <MenuItem value="priceDesc">Price: High to Low</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            value={budget}
                            placeholder="Enter budget"
                            onChange={handleBudgetInput}
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs:</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        {isManufacturersLoading ? (
                            <CircularProgress size={24}/>
                        ) : manufacturersError ? (
                            <Typography color="error">Error loading manufacturers</Typography>
                        ) : (
                            <FormControl fullWidth size="small">
                                <InputLabel id="manufacturer-label">Category</InputLabel>
                                <Select
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: (theme) =>
                                                    currentTheme === 'dark' ? theme.palette.background.paper : 'white',
                                            },
                                        },
                                    }}
                                    labelId="manufacturer-label"
                                    id="manufacturer"
                                    multiple
                                    value={selectedManufacturers}
                                    onChange={handleManufacturerChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    label="Manufacturer"
                                >
                                    {dataManufacturers && Array.isArray(dataManufacturers.Manufacturers) && dataManufacturers.Manufacturers.length > 0 ? (
                                        dataManufacturers.Manufacturers.map((manufacturer) => (
                                            <MenuItem key={manufacturer.id} value={manufacturer.manufacturer}>
                                                <Checkbox
                                                    checked={selectedManufacturers.indexOf(manufacturer.manufacturer) > -1}/>
                                                <ListItemText primary={manufacturer.manufacturer}/>
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No Manufacturers Available</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        {isClassificationLoading ? (
                            <CircularProgress size={24}/>
                        ) : classificationError ? (
                            <Typography color="error">Error loading classifications</Typography>
                        ) : (
                            <FormControl fullWidth size="small">
                                <InputLabel id="classification-label">Screen Size</InputLabel>
                                <Select
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: (theme) =>
                                                    currentTheme === 'dark' ? theme.palette.background.paper : 'white',
                                            },
                                        },
                                    }}
                                    labelId="classification-label"
                                    id="classification"
                                    multiple
                                    value={selectedClassifications}
                                    onChange={handleClassificationChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    label="Classification"
                                >
                                    {dataClassification.Classification.map((classification) => (
                                        <MenuItem key={classification.id} value={classification.classification}>
                                            <Checkbox
                                                checked={selectedClassifications.indexOf(classification.classification) > -1}/>
                                            <ListItemText primary={classification.classification}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        {isGenerationLoading ? (
                            <CircularProgress size={24}/>
                        ) : generationError ? (
                            <Typography color="error">Error loading classifications</Typography>
                        ) : (
                            <FormControl fullWidth size="small">
                                <InputLabel id="generation-label">Generations</InputLabel>
                                <Select
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: (theme) =>
                                                    currentTheme === 'dark' ? theme.palette.background.paper : 'white',
                                                maxHeight: 250,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                    labelId="generation-label"
                                    id="generation"
                                    multiple
                                    value={selectedGeneration}
                                    onChange={handleGenerationChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    label="Classification"
                                >
                                    {generation.Generation.map((generation) => (
                                        <MenuItem key={generation.id} value={generation.generation}>
                                            <Checkbox
                                                checked={selectedGeneration.indexOf(generation.generation) > -1}
                                            />
                                            <ListItemText primary={generation.generation} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="stock-status-label">Stock</InputLabel>
                            <Select
                                labelId="stock-status-label"
                                id="stock-status"
                                value={selectedFilters.stockStatus}
                                label="Stock"
                                onChange={(e) => handleStockStatusChange(e.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: (theme) =>
                                                currentTheme === 'dark' ? theme.palette.background.paper : 'white',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="inStock">In Stock</MenuItem>
                                <MenuItem value="outOfStock">Out of Stock</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                onClick={() => setViewMode('grid')}
                                color={viewMode === 'grid' ? 'primary' : 'default'}
                            >
                                <GridView />
                            </IconButton>
                            <IconButton
                                onClick={() => setViewMode('list')}
                                color={viewMode === 'list' ? 'primary' : 'default'}
                            >
                                <ViewList />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {isLoading ? (
                <Grid container spacing={2}>
                    {[...Array(12)].map((_, index) => (
                        <Grid
                            item
                            xs={viewMode === 'list' ? 12 : 6} // Show 1 item per row in list view
                            sm={viewMode === 'list' ? 12 : 6}
                            md={viewMode === 'list' ? 12 : 6}
                            lg={viewMode === 'list' ? 12 : 4}
                            key={index}
                        >
                            <ProductItemSkeleton viewMode={viewMode}  />
                        </Grid>
                    ))}
                </Grid>
            ) : error ? (
                <Typography color="error">Error loading items: {error}</Typography>
            ) : filteredItems.length === 0 ? (
                <Typography variant="h6" align="center" sx={{ mt: 4 }}>No items available</Typography>
            ) : (
                <Grid container spacing={2}>
                    {filteredItems.map((item) => (
                        <Grid
                            item
                            xs={viewMode === 'list' ? 12 : 6}
                            sm={viewMode === 'list' ? 12 : 6}
                            md={viewMode === 'list' ? 12 : 6}
                            lg={viewMode === 'list' ? 12 : 4}
                            key={item.id}
                        >
                            <ProductItem item={item} viewMode={viewMode} />
                        </Grid>
                    ))}
                </Grid>
            )}

        </Box>
    );
}