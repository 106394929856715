import React, {useEffect, useState, useCallback} from 'react';
import ShopPage from '../../components/Shop';
import {useGetCategory} from '../../hooks/useCategoryHook';
import {useGetSubCategory} from '../../hooks/useSubCategoryHook';
import {useFetchItems} from "../../hooks/useItemHook";
import {useGetClassifications} from '../../hooks/useClassificationHook';
import {useGetManufacturers} from '../../hooks/useManufacturerHook';
import {useGetGenerations} from "../../hooks/useGenerationHook";

function Shop() {
    const {data: dataCategory, isLoading: isCategoryLoading, error: categoryError} = useGetCategory();
    const {
        data: dataClassification,
        isLoading: isClassificationLoading,
        error: classificationError
    } = useGetClassifications();
    const {
        data: dataManufacturers,
        isLoading: isManufacturersLoading,
        error: manufacturersError
    } = useGetManufacturers();

    const [categoryID, setCategoryID] = useState('');
    const [subCategoryID, setSubCategoryID] = useState('');

    const onCategorySelect = useCallback((selectedCategory) => {
        setCategoryID(selectedCategory);
    }, []);

    const onSubCategoryChange = useCallback((selectedSubCategory) => {
        setSubCategoryID(selectedSubCategory);
    }, []);

    const {data: dataSubCategory, isLoading: isSubCategoryLoading, error: subCategoryError} = useGetSubCategory({
        category_ids: categoryID
    });

    const {data: dataItems, isLoading: isItemsLoading, error: itemsError} = useFetchItems({
        category_ids: categoryID,
        sub_category_ids: subCategoryID
    });
const {data: dataGeneration, isLoading: isGenerationLoading, error: generationError} = useGetGenerations();

    return (
        <ShopPage
            category={dataCategory || []}
            isCategoryLoading={isCategoryLoading}
            errorCategory={categoryError}
            subCategory={dataSubCategory || []}
            isSubCategoryLoading={isSubCategoryLoading}
            errorSubCategory={subCategoryError}
            items={dataItems || []}
            isItemsLoading={isItemsLoading}
            itemsError={itemsError}
            onCategorySelect={onCategorySelect}
            onSubCategoryChange={onSubCategoryChange}
            dataClassification={dataClassification}
            isClassificationLoading={isClassificationLoading}
            classificationError={classificationError}
            dataManufacturers={dataManufacturers || []}
            isManufacturersLoading={isManufacturersLoading}
            manufacturersError={manufacturersError}
            generation={dataGeneration || []}
            isGenerationLoading={isGenerationLoading}
            generationError={generationError}
        />
    );
}

export default Shop;