import React, { useState, useRef, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Paper,
    Typography,
    TextField,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Email, LocationOn, Phone, Web, Close, Add } from "@mui/icons-material";
import html2canvas from 'html2canvas';
import logo from "../../../assets/img/logo.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '700px',
    margin: 'auto',
    backgroundColor: '#fff',
    overflow: 'auto',
}));

const ProductImage = styled('img')({
    width: '500px',
    height: 'auto',
    borderRadius: '12px',
    marginBottom: '1rem',
});

const ProductName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    padding: '5px 10px',
    color: 'white',
    width: 'fit-content',
    margin: '30px',
}));

const FeatureList = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: '8px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '600px',
    justifyItems: 'center',
    alignItems: 'center',
}));

const BrochurePreviewModal = ({ open, onClose, product, getImageUrl, onSave }) => {
    const [editedProduct, setEditedProduct] = useState(product);
    const [isEditing, setIsEditing] = useState(false);
    const contentRef = useRef(null);
    useEffect(() => {
        const previousLineHeight = document.body.style.lineHeight;
        document.body.style.lineHeight = '0.5';
        return () => {
            document.body.style.lineHeight = previousLineHeight;
        };
    }, []);

    useEffect(() => {
        setEditedProduct(product);
    }, [product]);

    const handleEdit = (field, value) => {
        setEditedProduct(prev => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
        onSave(editedProduct);
        setIsEditing(false);
    };

    const handleDownload = async () => {
        if (contentRef.current) {
            try {
                const removeIcons = contentRef.current.querySelectorAll('.remove-icon');
                removeIcons.forEach(icon => icon.style.display = 'none');
                const canvas = await html2canvas(contentRef.current, {
                    scale: 2,
                    useCORS: true,
                    logging: false,
                });
                removeIcons.forEach(icon => icon.style.display = '');
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = image;
                link.download = `${editedProduct.name}-brochure.png`;
                link.click();
            } catch (error) {
                console.error('Error generating image:', error);
            }
        }
    };

    const handleRemoveDetail = (index) => {
        setEditedProduct(prev => ({
            ...prev,
            remarks: prev.remarks.filter((_, i) => i !== index)
        }));
    };

    const handleAddDetail = () => {
        setEditedProduct(prev => ({
            ...prev,
            remarks: [...(prev.remarks || []), '']
        }));
    };

    if (!product) {
        return <Typography>No product data available</Typography>;
    }

    const details = [
        ...(editedProduct?.remarks || []),
        ...(editedProduct?.classification ? [editedProduct.classification] : [])
    ].filter(Boolean);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { backgroundColor: '#fff', boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)', maxWidth: "fit-content"} }}
        >
            <DialogTitle>Brochure Preview</DialogTitle>
            <DialogContent >
                <StyledPaper elevation={3} ref={contentRef} sx={{boxShadow: 'none'}} >
                    <ProductName variant="h4" align="left" style={{ backgroundColor: "#0495d5", borderRadius: 4 }}>
                        {isEditing ? (
                            <TextField
                                value={editedProduct?.name || ''}
                                onChange={(e) => handleEdit('name', e.target.value)}
                                variant="standard"
                                InputProps={{ style: { color: 'white' } }}
                            />
                        ) : (
                            editedProduct?.name
                        )}
                    </ProductName>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        minHeight: '100%',
                        minWidth: '100%',
                    }}>
                        <Box sx={{
                            position: 'relative',
                            display: 'inline-block',
                        }}>
                            <ProductImage src={getImageUrl(editedProduct?.images?.[0])} alt={editedProduct?.name || 'Unnamed Product'} />
                            {editedProduct.sale_price && (
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    sx={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        backgroundColor: '#D84315',
                                        color: 'white',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        minWidth: 'fit-content',
                                    }}
                                >
                                    {isEditing ? (
                                        <TextField
                                            value={editedProduct.sale_price}
                                            onChange={(e) => handleEdit('sale_price', e.target.value)}
                                            variant="standard"
                                            InputProps={{ style: { color: 'white' } }}
                                        />
                                    ) : (
                                        new Intl.NumberFormat('en-PK', {
                                            style: 'currency',
                                            currency: 'PKR',
                                            minimumFractionDigits: 0,
                                        }).format(editedProduct.sale_price)
                                    )}
                                    {isEditing && (
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEdit('sale_price', '')}
                                            sx={{ color: 'white', position: 'absolute', top: -8, right: -8 }}
                                            className="remove-icon"
                                        >
                                            <Close fontSize="small" />
                                        </IconButton>
                                    )}
                                </Typography>
                            )}
                        </Box>
                        <FeatureList>
                            {[...(editedProduct.remarks || []), ...(editedProduct.classification ? [editedProduct.classification] : [])].map((item, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        gridColumn: index === details.length - 1 && details.length % 2 !== 0 ? '1 / -1' : 'auto',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            padding: '8px',
                                            backgroundColor: index % 2 === 0 ? '#393939' : '#0495d5',
                                            color: 'white',
                                            borderRadius: '4px',
                                            minWidth: '150px',
                                            transform: 'skew(-10deg)',
                                            width: index === details.length - 1 && details.length % 2 !== 0 ? '50%' : '100%',
                                            margin: index === details.length - 1 && details.length % 2 !== 0 ? '0 auto' : '0',
                                        }}
                                    >
                                        {isEditing ? (
                                            <TextField
                                                value={item || ''}
                                                onChange={(e) => {
                                                    if (index < (editedProduct.remarks?.length || 0)) {
                                                        const newRemarks = [...(editedProduct.remarks || [])];
                                                        newRemarks[index] = e.target.value;
                                                        handleEdit('remarks', newRemarks);
                                                    } else {
                                                        handleEdit('classification', e.target.value);
                                                    }
                                                }}
                                                variant="standard"
                                                InputProps={{ style: { color: 'white' } }}
                                            />
                                        ) : (
                                            item
                                        )}
                                    </Typography>
                                    {isEditing && index < (editedProduct.remarks?.length || 0) && (
                                        <IconButton
                                            size="small"
                                            onClick={() => handleRemoveDetail(index)}
                                            sx={{ position: 'absolute', top: -8, right: -8, color: 'white' }}
                                            className="remove-icon"
                                        >
                                            <Close fontSize="small" />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                            {isEditing && (
                                <Button
                                    onClick={handleAddDetail}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Add />}
                                    sx={{ gridColumn: '1 / -1' }}
                                >
                                    Add Detail
                                </Button>
                            )}
                        </FeatureList>
                    </Box>
                    <Box backgroundColor="#0495d5" paddingTop={0.5}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                alignItems: 'center',
                                gap: 2,
                                width: '100%',
                                padding: '10px',
                                backgroundColor: "#393939"
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={logo}
                                    alt="Conix Logo"
                                    style={{ height: '40px', width: 'auto', marginRight: '8px' }}
                                />
                                <Typography
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#D84315',
                                        borderRadius: 1,
                                        fontSize: 25,
                                        padding: 2,
                                        fontWeight: 'bold',
                                        color: 'white',
                                    }}
                                >
                                    System
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Box marginBottom={1} sx={{ display: 'flex', flexDirection: 'flex-start', alignItems: 'center' }}>
                                    <LocationOn sx={{ fontSize: 15, color: '#fff', marginRight: '4px' }} />
                                    <Typography
                                        variant="caption"
                                        color='white'
                                        contentEditable={isEditing}
                                        suppressContentEditableWarning
                                        onBlur={(e) => handleEdit('location', e.target.textContent)}
                                    >
                                        {editedProduct.location || "Location: Shop 82 3rd floor Hafeez Center Lahore"}
                                    </Typography>
                                </Box>
                                <Box marginBottom={1} sx={{ display: 'flex', flexDirection: 'flex-start', alignItems: 'center' }}>
                                    <Phone sx={{ fontSize: 15, color: '#fff', marginRight: '4px' }} />
                                    <Typography
                                        variant="caption"
                                        color='white'
                                        contentEditable={isEditing}
                                        suppressContentEditableWarning
                                        onBlur={(e) => handleEdit('phone', e.target.textContent)}
                                    >
                                        {editedProduct.phone || "Phone: 03254290003"}
                                    </Typography>
                                </Box>
                                <Box marginBottom={1} sx={{ display: 'flex', flexDirection: 'flex-start', alignItems: 'center' }}>
                                    <Email sx={{ fontSize: 15, color: '#fff', marginRight: '4px' }} />
                                    <Typography
                                        variant="caption"
                                        color='white'
                                        contentEditable={isEditing}
                                        suppressContentEditableWarning
                                        onBlur={(e) => handleEdit('email', e.target.textContent)}
                                    >
                                        {editedProduct.email || "Email: conixsystem@gmail.com"}
                                    </Typography>
                                </Box>
                                <Box marginBottom={1} sx={{ display: 'flex', flexDirection: 'flex-start', alignItems: 'center' }}>
                                    <Web sx={{ fontSize: 15, color: '#fff', marginRight: '4px' }} />
                                    <Typography
                                        variant="caption"
                                        color='white'
                                        contentEditable={isEditing}
                                        suppressContentEditableWarning
                                        onBlur={(e) => handleEdit('website', e.target.textContent)}
                                    >
                                        {editedProduct.website || "Web: www.conixsystem.com"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </StyledPaper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                {isEditing ? (
                    <Button onClick={handleSave} variant="contained" color="primary">
                        Save Changes
                    </Button>
                ) : (
                    <Button onClick={() => setIsEditing(true)} variant="contained" color="primary">
                        Edit
                    </Button>
                )}
                <Button onClick={handleDownload} variant="contained" color="primary">
                    Download Brochure
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BrochurePreviewModal;

