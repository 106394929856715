import React from 'react';
import ProductGrid from '../../components/Other/ProductGrid';
import { useFetchItems } from "../../hooks/useItemHook";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

function Other() {
    const { categoryID, subCategoryID } = useParams();
    var category = categoryID.replace(/\D/g, '');
    var subCategory = subCategoryID.replace(/\D/g, '');
    const parsedCategoryID = category ? Number(category) : undefined;
    const parsedSubCategoryID = subCategory ? Number(subCategory) : undefined;

    const { data: dataItems, isLoading: isItemsLoading, error: itemsError } = useFetchItems({
        category_ids: parsedCategoryID ? [parsedCategoryID] : undefined,
        sub_category_ids: parsedSubCategoryID ? [parsedSubCategoryID] : undefined,
    });

    return (
        <Container>
            <ProductGrid
                items={dataItems}
                isLoading={isItemsLoading}
                error={itemsError}
            />
        </Container>
    );
}

export default Other;
