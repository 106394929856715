// src/Routes.js
import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Home from "../pages/Home/home";
import Contact from "../pages/Contact/contact";
import Shop from "../pages/Shop/shop"
import ProductDetailPage from "../components/Shop/ProductDetail/ProductDetailPage";
import Other from "../pages/Other/other";
const Routes = () => {
    return (
        <RouterRoutes>
            <Route path="/" element={<Home />} />
            <Route path="/laptops" element={<Shop />} />
            <Route path="/contact" element={<Contact />}/>
            <Route path=":name" element={<ProductDetailPage />} />
            <Route path="/:categoryID/:subCategoryID" element={<Other />} />

        </RouterRoutes>
    );
};

export default Routes;
