'use client'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Button,
    Modal,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Box,
    Paper,
    Popper,
    Fade,
    Container,
} from '@mui/material'
import {
    Menu as MenuIcon,
    Close as CloseIcon,
    ExpandMore as ExpandMoreIcon,
    ChevronRight as ChevronRightIcon,
    DarkMode as DarkModeIcon,
    LightMode as LightModeIcon,
    Palette as PaletteIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useTheme } from "../../Provider/ThemeProvider"
import { useGetCategory } from "../../hooks/useCategoryHook"
import { useGetSubCategory } from "../../hooks/useSubCategoryHook"
import { useMediaQuery } from "@mui/material"
import { useTheme as useThemeContext } from "@mui/material/styles"
import logo from '../../assets/img/logo.png'

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    top: '100%',
    display: 'flex',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    zIndex: theme.zIndex.appBar + 999,
    backgroundColor: theme.palette.background.main,
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
    color: theme.palette.secondary.main,
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
}))

const Navbar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useThemeContext()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [isOpen, setIsOpen] = useState(false)
    const [showCategories, setShowCategories] = useState(false)
    const [activeCategory, setActiveCategory] = useState(null)
    const [sidebarCategories, setSidebarCategories] = useState(false)
    const { currentTheme, setTheme } = useTheme()
    const { data: dataCategory, isLoading: isCategoryLoading, error: categoryError } = useGetCategory()
    const { data: dataSubCategory, isLoading: isSubCategoryLoading, error: subCategoryError } = useGetSubCategory()
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)

    const dropdownRef = useRef(null)
    const browseButtonRef = useRef(null)

    useEffect(() => {
        if (dataCategory) {
            setCategories(dataCategory)
        }
    }, [dataCategory])

    useEffect(() => {
        if (dataSubCategory) {
            const subCategoriesMap = {}
            dataSubCategory.forEach(subCat => {
                if (!subCategoriesMap[subCat.item_category]) {
                    subCategoriesMap[subCat.item_category] = []
                }
                subCategoriesMap[subCat.item_category].push(subCat)
            })
            setSubCategories(subCategoriesMap)
        }
    }, [dataSubCategory])

    const toggleSidebar = () => setIsOpen(!isOpen)
    const toggleCategories = useCallback((event) => {
        setAnchorEl(event.currentTarget)
        setShowCategories(prev => !prev)
    }, [])
    const handleCategoryHover = (categoryId) => {
        setActiveCategory(categoryId)
    }

    const toggleSidebarCategories = () => setSidebarCategories(!sidebarCategories)

    const getThemeIcon = () => {
        if (currentTheme === 'light') return <DarkModeIcon sx={{ color: theme.palette.icon.default }} />
        if (currentTheme === 'dark') return <LightModeIcon sx={{ color: theme.palette.icon.main }} />
        return <PaletteIcon sx={{ color: theme.palette.icon.main }} />
    }

    const handleSubCategoryClick = (categoryId, subCatId, event) => {
        event.preventDefault()
        const linkTo = `/category=${categoryId}/subcategory=${subCatId}`
        navigate(linkTo)
        setShowCategories(false)
        setIsOpen(false)
    }

    const isActiveRoute = (path) => {
        return location.pathname === path
    }

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'default'
        setTheme(savedTheme)
    }, [setTheme])

    const toggleTheme = () => {
        const newTheme = currentTheme === 'light' ? 'dark' : currentTheme === 'dark' ? 'default' : 'light'
        setTheme(newTheme)
        localStorage.setItem('theme', newTheme)
    }

    return (
        <AppBar position="static" color="default" sx={{ backgroundImage: 'none', backgroundColor: `${theme.palette.background.paper} !important` }}>
            <Container>
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Box component={Link} to="/" sx={{ flexGrow: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <img
                            src={logo}
                            alt="Conix Logo"
                            style={{ height: '40px', width: 'auto' }}
                        />
                    </Box>
                    <IconButton
                        color="inherit"
                        onClick={toggleTheme}
                        aria-label={`Switch to ${currentTheme === 'light' ? 'dark' : currentTheme === 'dark' ? 'default' : 'light'} mode`}
                        title={`Switch to ${currentTheme === 'light' ? 'dark' : currentTheme === 'dark' ? 'default' : 'light'} mode`}
                    >
                        {getThemeIcon()}
                    </IconButton>
                    <IconButton
                        sx={{ display: { xs: 'block', md: 'none' } }}
                        color="inherit"
                        onClick={toggleSidebar}
                        aria-label="Toggle menu"
                    >
                        {isOpen ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </Toolbar>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', p: 1, gap: '10px' }}>
                    <Button
                        color="inherit"
                        onClick={toggleCategories}
                        endIcon={<ExpandMoreIcon />}
                        ref={browseButtonRef}
                        aria-haspopup="true"
                        aria-expanded={showCategories}
                        sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: 'bold',
                            backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                            }
                        }}
                    >
                        Browse Categories
                    </Button>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/"
                        sx={{
                            fontWeight: 'bold',
                            color: isActiveRoute('/') ? 'inherit' : theme.palette.secondary.main,
                            backgroundColor: isActiveRoute('/') ? theme.palette.secondary.main : 'inherit',
                            '&:hover': {
                                backgroundColor: isActiveRoute('/') ? theme.palette.secondary.main : 'action.hover',
                            }
                        }}
                    >
                        Home
                    </Button>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/laptops"
                        sx={{
                            fontWeight: 'bold',
                            color: isActiveRoute('/laptops') ? 'inherit' : theme.palette.secondary.main,
                            backgroundColor: isActiveRoute('/laptops') ? theme.palette.secondary.main : 'inherit',
                            '&:hover': {
                                backgroundColor: isActiveRoute('/laptops') ? theme.palette.secondary.main : 'action.hover',
                            }
                        }}
                    >
                        Laptops
                    </Button>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/contact"
                        sx={{
                            fontWeight: 'bold',
                            color: isActiveRoute('/contact') ? 'inherit' : theme.palette.secondary.main,
                            backgroundColor: isActiveRoute('/contact') ? theme.palette.secondary.main : 'inherit',
                            '&:hover': {
                                backgroundColor: isActiveRoute('/contact') ? theme.palette.secondary.main : 'action.hover',
                            }
                        }}
                    >
                        Contact Us
                    </Button>
                </Box>
            </Container>
            <Popper
                open={showCategories}
                anchorEl={anchorEl}
                placement="bottom-start"
                transition
                onMouseLeave={() => setShowCategories(false)}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <StyledPaper elevation={3} ref={dropdownRef}>
                            <Box sx={{ display: 'flex' }}>
                                <List sx={{
                                    minWidth: '200px',
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    maxHeight: '400px', // Set a max height
                                    overflowY: 'auto'  // Make it scrollable
                                }}>
                                    {categories.map((category) => (
                                        <StyledListItem
                                            button
                                            key={category.id}
                                            onMouseEnter={() => handleCategoryHover(category.id)}
                                            sx={{
                                                backgroundColor: activeCategory === category.id ? 'action.selected' : 'inherit',
                                            }}
                                        >
                                            <ListItemText primary={category.desc} />
                                            {subCategories[category.id] && <ChevronRightIcon />}
                                        </StyledListItem>
                                    ))}
                                </List>
                                <Box sx={{
                                    p: 2,
                                    minWidth: '300px',
                                    height: '400px', // Match the max height of the category list
                                    overflowY: 'auto' // Make it scrollable if content exceeds height
                                }}>
                                    {activeCategory && (
                                        <>
                                            <Typography variant="h6" color='white' gutterBottom>
                                                {categories.find(cat => cat.id === activeCategory)?.desc}
                                            </Typography>
                                            <List>
                                                {subCategories[activeCategory]?.map((subCat) => (
                                                    <StyledListItem
                                                        button
                                                        component={Link}
                                                        to={`/products/${activeCategory}/${subCat.id}`}
                                                        key={subCat.id}
                                                        onClick={(event) => handleSubCategoryClick(activeCategory, subCat.id, event)}
                                                    >
                                                        <ListItemText primary={subCat.desc} />
                                                    </StyledListItem>
                                                ))}
                                            </List>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </StyledPaper>
                    </Fade>
                )}
            </Popper>
            <Modal
                open={isOpen}
                onClose={toggleSidebar}
                aria-labelledby="side-modal-title"
                aria-describedby="side-modal-description"
            >
                <Box
                    sx={{
                        backgroundImage: 'none',
                        backgroundColor: `${theme.palette.background.paper} !important`,
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        width: '100%',
                        maxWidth: isMobile ? '100%' : 400,
                        height: '100%',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <StyledTypography variant="h6" component="h2" id="side-modal-title">
                            Menu
                        </StyledTypography>
                        <IconButton onClick={toggleSidebar} aria-label="close">
                            <CloseIcon sx={{ color: theme.palette.secondary.main }} />
                        </IconButton>
                    </Box>
                    <List id="side-modal-description">
                        <StyledListItem button component={Link} to="/" onClick={toggleSidebar}>
                            <ListItemText
                                primary="Home"
                                primaryTypographyProps={{ style: { color: theme.palette.secondary.main } }}
                            />
                        </StyledListItem>
                        <ListItem button onClick={toggleSidebarCategories}>
                            <ListItemText
                                primary="Browse Categories"
                                primaryTypographyProps={{ style: { color: theme.palette.secondary.main } }}
                            />
                            {sidebarCategories ?
                                <ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} /> :
                                <ChevronRightIcon sx={{ color: theme.palette.secondary.main }} />
                            }
                        </ListItem>
                        <Collapse in={sidebarCategories} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {categories.map((category) => (
                                    <React.Fragment key={category.id}>
                                        <StyledListItem button onClick={() => handleCategoryHover(category.id)} sx={{ pl: 4 }}>
                                            <ListItemText
                                                primary={category.desc}
                                                primaryTypographyProps={{ style: { color: theme.palette.secondary.main } }}
                                            />
                                            {subCategories[category.id] && (
                                                activeCategory === category.id ?
                                                    <ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} /> :
                                                    <ChevronRightIcon sx={{ color: theme.palette.secondary.main }} />
                                            )}
                                        </StyledListItem>
                                        <Collapse in={activeCategory === category.id} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {subCategories[category.id]?.map((subCat) => (
                                                    <StyledListItem
                                                        button
                                                        component={Link}
                                                        to={`/laptops/${category.id}/${subCat.id}`}
                                                        key={subCat.id}
                                                        sx={{ pl: 6 }}
                                                        onClick={(event) => handleSubCategoryClick(category.id, subCat.id, event)}
                                                    >
                                                        <ListItemText
                                                            primary={subCat.desc}
                                                            primaryTypographyProps={{ style: { color: theme.palette.secondary.main } }}
                                                        />
                                                    </StyledListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Collapse>
                        <StyledListItem button component={Link} to="/laptops" onClick={toggleSidebar}>
                            <ListItemText
                                primary="Laptops"
                                primaryTypographyProps={{ style: { color: theme.palette.secondary.main } }}
                            />
                        </StyledListItem>
                        <StyledListItem button component={Link} to="/contact" onClick={toggleSidebar}>
                            <ListItemText
                                primary="Contact Us"
                                primaryTypographyProps={{ style: { color: theme.palette.secondary.main } }}
                            />
                        </StyledListItem>
                    </List>
                </Box>
            </Modal>
        </AppBar>
    )
}

export default Navbar
