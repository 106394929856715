import React, { useState } from 'react';
import {
    Grid, Typography, Box, Paper, Chip, IconButton,
    FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment,
    Pagination
} from '@mui/material';
import { GridView, ViewList } from '@mui/icons-material';
import ProductItem from '../ProductItem';
import ProductItemSkeleton from '../../../components/Shop/ProductSkeleton';
import {useTheme} from "../../../Provider/ThemeProvider";

export default function ProductGrid({ items = [], isLoading}) {
    const { currentTheme } = useTheme()
    const [viewMode, setViewMode] = useState('grid');
    const [sortBy, setSortBy] = useState('popularity');
    const [budget, setBudget] = useState('');
    const [stockStatus, setStockStatus] = useState('inStock');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 18;

    const handleBudgetInput = (event) => {
        setBudget(event.target.value);
    };

    const handleStockStatusChange = (event) => {
        setStockStatus(event.target.value);
        console.log(event.target.value);
    };

    const filteredItems = items.filter(item => {
        if (!item) return false;
        if (budget && (item.sale_price || 0) > Number(budget)) {
            return false;
        }
        if (stockStatus === 'inStock' && (item.current_stock || 0) <= 0) {
            return false;
        }
        if (stockStatus === 'outOfStock' && (item.current_stock || 0) > 0) {
            return false;
        }
        return true;
    });

    const sortedItems = [...filteredItems].sort((a, b) => {
        if (sortBy === 'priceAsc') {
            return (a.sale_price || 0) - (b.sale_price || 0);
        } else if (sortBy === 'priceDesc') {
            return (b.sale_price || 0) - (a.sale_price || 0);
        }
        return items.indexOf(a) - items.indexOf(b);
    });

    const pageCount = Math.ceil(sortedItems.length / itemsPerPage);
    const displayedItems = sortedItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Box marginY={3}>
            <Paper elevation={3} sx={{mb: 3, p: 2,  backgroundColor: (theme) =>
                    currentTheme === 'dark' ? theme.palette.background.paper : 'transparent',
                boxShadow:
                    currentTheme === 'dark'
                        ? '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                        : 'none'}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: "'PT Serif', serif",
                            fontSize: '28px',
                            lineHeight: '31px'
                        }}
                    >
                        Products
                    </Typography>
                    <Chip
                        label={`${sortedItems.length} items`}
                        color="primary"
                        variant="outlined"
                        sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                    />
                </Box>
            </Paper>

            <Paper elevation={3} sx={{mb: 3, p: 2,  backgroundColor: (theme) =>
                    currentTheme === 'dark' ? theme.palette.background.paper : 'transparent',
                boxShadow:
                    currentTheme === 'dark'
                        ? '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                        : 'none'}} >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="sort-by-label">Sort By</InputLabel>
                            <Select
                                labelId="sort-by-label"
                                id="sort-by"
                                value={sortBy}
                                label="Sort By"
                                onChange={(e) => setSortBy(e.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: (theme) =>
                                                currentTheme === 'dark' ? theme.palette.background.paper : 'white',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="popularity">Popularity</MenuItem>
                                <MenuItem value="priceAsc">Price: Low to High</MenuItem>
                                <MenuItem value="priceDesc">Price: High to Low</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            value={budget}
                            placeholder="Enter budget"
                            onChange={handleBudgetInput}
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs:</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="stock-status-label" sx={{zIndex: -99}}>Stock</InputLabel>
                            <Select
                                labelId="stock-status-label"
                                id="stock-status"
                                value={stockStatus}
                                label="Stock"
                                onChange={handleStockStatusChange}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: (theme) =>
                                                currentTheme === 'dark' ? theme.palette.background.paper : 'white',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="inStock">In Stock</MenuItem>
                                <MenuItem value="outOfStock">Out of Stock</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                onClick={() => setViewMode('grid')}
                                color={viewMode === 'grid' ? 'primary' : 'default'}
                            >
                                <GridView />
                            </IconButton>
                            <IconButton
                                onClick={() => setViewMode('list')}
                                color={viewMode === 'list' ? 'primary' : 'default'}
                            >
                                <ViewList />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {isLoading ? (
                <Grid container spacing={2}>
                    {Array.from(new Array(12)).map((_, index) => (
                        <Grid
                            item
                            xs={viewMode === 'list' ? 12 : 6}
                            sm={viewMode === 'list' ? 12 : 6}
                            md={viewMode === 'list' ? 12 : 4}
                            key={index}
                        >
                            <ProductItemSkeleton viewMode={viewMode} />
                        </Grid>
                    ))}
                </Grid>
            ) : sortedItems.length === 0 ? (
                <Paper elevation={3} sx={{p: 3, textAlign: 'center', backgroundColor: (theme) =>
                        currentTheme === 'dark' ? theme.palette.background.paper : 'transparent',
                    boxShadow:
                        currentTheme === 'dark'
                            ? '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                            : 'none'}} >
                    <Typography variant="h6">
                        No products found. Please try adjusting your filters.
                    </Typography>
                </Paper>
            ) : (
                <>
                    <Grid container spacing={2}>
                        {displayedItems.map((item) => (
                            <Grid
                                item
                                xs={viewMode === 'list' ? 12 : 6}
                                sm={viewMode === 'list' ? 12 : 6}
                                md={viewMode === 'list' ? 12 : 4}
                                key={item?.id || Math.random().toString(36).substr(2, 9)}
                            >
                                <ProductItem item={item} viewMode={viewMode} />
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={pageCount}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            size="large"
                        />
                    </Box>
                </>
            )}
        </Box>
    );
}

