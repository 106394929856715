import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import {
    Typography,
    Button,
    Grid,
    Box,
    Chip,
    Tabs,
    Tab,
    Paper,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShareIcon from '@mui/icons-material/Share';
import PreviewIcon from '@mui/icons-material/Visibility';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Loader from '../../common/loader';
import ReactGA from 'react-ga4';
import { Tablet, Laptop, Smartphone } from 'lucide-react';
import BrochurePreviewModal from '../ProductBroucherModal/BrochurePreviewModal';
import placeholder from '../../../assets/img/placeholder.png';
const StyledTabsContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.paper,
}));

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'primary.main',
    },
});

const StyledTab = styled(Tab)({
    '&.Mui-selected': {
        color: '#fff',
    },
});

const ScrollableImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '-ms-overflow-style': 'none',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
    },
}));

const ImageMagnifier = ({
                            src,
                            className,
                            alt,
                            magnifierHeight = 150,
                            magnifierWidth = 150,
                            zoomLevel = 1.5
                        }) => {
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [[x, y], setXY] = useState([0, 0]);

    const mouseEnter = (e) => {
        const el = e.currentTarget;
        const { width, height } = el.getBoundingClientRect();
        setSize([width, height]);
        setShowMagnifier(true);
    };

    const mouseLeave = () => {
        setShowMagnifier(false);
    };

    const mouseMove = (e) => {
        const el = e.currentTarget;
        const { top, left } = el.getBoundingClientRect();

        const x = e.pageX - left - window.scrollX;
        const y = e.pageY - top - window.scrollY;

        setXY([x, y]);
    };

    return (
        <div
            className="flex justify-center items-center"
            style={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: '400px',
            }}
        >
            <div className="relative inline-block">
                <img
                    src={src}
                    className={className}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'unset',
                    }}
                    alt={alt}
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                    onMouseMove={mouseMove}
                />
                <div
                    style={{
                        display: showMagnifier ? '' : 'none',
                        position: 'absolute',
                        pointerEvents: 'none',
                        height: `${magnifierHeight}px`,
                        width: `${magnifierWidth}px`,
                        opacity: '1',
                        border: '1px solid lightgrey',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        backgroundImage: `url('${src}')`,
                        backgroundRepeat: 'no-repeat',
                        top: `${y - magnifierHeight / 2}px`,
                        left: `${x - magnifierWidth / 2}px`,
                        backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
                        backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                        backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
                    }}
                />
            </div>
        </div>
    );
};

export default function ProductDetailPage() {
    const { name } = useParams();
    const [activeImage, setActiveImage] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deviceType, setDeviceType] = useState(null);
    const [userCounts, setUserCounts] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [editedProduct, setEditedProduct] = useState(null);

    const specificationsRef = useRef(null);
    const accessoriesRef = useRef(null);

    const location = useLocation();
    const productUrl = `${window.location.origin}${location.pathname}`;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const fetchProduct = async () => {
            setLoading(true);
            try {
                if (location.state?.item) {
                    setProduct(location.state.item);
                    setDeviceType(location.state.deviceType);
                    setUserCounts(location.state.userCounts);
                } else {
                    const response = await fetch(`https://posnew.conixsystem.com/api/itemName?name=${encodeURIComponent(name)}`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch product');
                    }
                    const data = await response.json();
                    if (data.items && data.items.length > 0) {
                        setProduct(data.items[0]);
                        setDeviceType(getDeviceType());
                    } else {
                        throw new Error('Product not found');
                    }
                }

                ReactGA.send({
                    hitType: 'pageview',
                    page: location.pathname,
                    title: name,
                    deviceType: deviceType || getDeviceType()
                });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [name, location.state, location.pathname]);

    const getDeviceType = () => {
        if (window.innerWidth <= 768) return 'phone';
        if (window.innerWidth <= 1024) return 'tablet';
        return 'laptop';
    };

    useEffect(() => {
        const socketConnection = new WebSocket('wss://posnew.conixsystem.com/ws/');

        socketConnection.onopen = () => {
            console.log('WebSocket connected');
            if (product) {
                const deviceType = getDeviceType();
                const message = {
                    productId: product.id,
                    deviceType: deviceType,
                };
                socketConnection.send(JSON.stringify(message));
            }
        };

        socketConnection.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message && message.productId === product?.id) {
                setUserCounts(message.counts);
                console.log(message);
            }
        };

        socketConnection.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socketConnection.onclose = () => {
            console.log('WebSocket disconnected');
        };

        return () => {
            socketConnection.close();
        };
    }, [product?.id]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        const refs = [specificationsRef, accessoriesRef];
        refs[newValue].current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 100;
            const sections = [
                { ref: specificationsRef, index: 0 },
                { ref: accessoriesRef, index: 1 },
            ];

            for (const section of sections) {
                if (section.ref.current && scrollPosition >= section.ref.current.offsetTop) {
                    setActiveTab(section.index);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const getImageUrl = (imagePath) => {
        if (!imagePath) return placeholder;
        if (imagePath.startsWith('http')) {
            return encodeURI(imagePath);
        }
        return encodeURI(`https://posnew.conixsystem.com/${imagePath}`);
    };

    const createWhatsAppLink = async (isShare) => {
        try {
            const imageUrl = `https://posnew.conixsystem.com/api/getImage?productId=${product.id}`;

            const formattedPrice = new Intl.NumberFormat('en-PK', {
                style: 'currency',
                currency: 'PKR',
                minimumFractionDigits: 0,
            }).format(product.sale_price);
            let message = `${imageUrl}\n\n`;
            message += `*${product.name}*\n\n`;
            message += `${product.remarks && product.remarks.length > 0 ? product.remarks.map(remark => `• ${remark}`).join('\n') + '\n' : ''}`;
            message += `${product.classification ? `• ${product.classification}\n` : ''}`;
            message += `\n*Price: ${formattedPrice}*\n\n`;
            message += `Available At\n*Conix System*\n`;
            message += `Shop 82 3rd floor Hafeez Center Lahore\n\n`;
            message += `*Phone: 03254290003*\n`;
            message += `Product: ${productUrl}`;

            if (isShare) {
                return `https://wa.me/?text=${encodeURIComponent(message)}`;
            } else {
                return `https://wa.me/923254290003?text=${encodeURIComponent(message)}`;
            }
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const handlePreviewOpen = () => {
        setIsPreviewOpen(true);
    };

    const handlePreviewClose = () => {
        setIsPreviewOpen(false);
    };

    const handleSaveBrochure = (updatedProduct) => { //Update 3
        setEditedProduct(updatedProduct);
        console.log("Saved product:", updatedProduct);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Loader />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
                <Typography variant="h5" color="error" gutterBottom>
                    Error: {error}
                </Typography>
                <Button component={Link} to="/laptops" variant="contained">
                    Back to Laptops
                </Button>
            </Box>
        );
    }

    if (!product) {
        return (
            <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Product not found
                </Typography>
                <Button component={Link} to="/laptops" variant="contained">
                    Back to Shop
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
            <Button component={Link} to="/laptops" variant="outlined" sx={{ mb: 2 }}>
                Back to Laptops
            </Button>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <ImageMagnifier
                            src={getImageUrl(product.images?.[activeImage])}
                            alt={product.name || 'Product Image'}
                            width="100%"
                            height={isMobile ? "300px" : "400px"}
                            className="object-contain"
                            magnifierHeight={150}
                            magnifierWidth={150}
                            zoomLevel={1.5}
                        />
                    </Box>
                    <ScrollableImageContainer sx={{ mt: 2 }}>
                        {(product.images || []).map((image, index) => (
                            <Button
                                key={index}
                                onClick={() => setActiveImage(index)}
                                sx={{
                                    width: 60,
                                    height: 60,
                                    flexShrink: 0,
                                    p: 0,
                                    m: 0.5,
                                    border: index === activeImage ? '2px solid' : 'none',
                                    borderColor: 'primary.main',
                                }}
                            >
                                <img
                                    src={getImageUrl(image)}
                                    alt={`${product.name} thumbnail ${index + 1}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'scale-down' }}
                                />
                            </Button>
                        ))}
                    </ScrollableImageContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>
                        {product.name || 'Unnamed Product'}
                    </Typography>
                    <Box display="flex" alignItems="center" gap={2} mb={2}>
                        <Box display="flex" alignItems="center">
                            <Tablet style={{ color: 'black' }} />
                            <span>{` ${userCounts?.tablet || 0}`}</span>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Laptop style={{ color: 'black' }} />
                            <span>{` ${userCounts?.laptop || 0}`}</span>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Smartphone style={{ color: 'black' }} />
                            <span>{` ${userCounts?.phone || 0}`}</span>
                        </Box>
                    </Box>

                    <Typography variant="body1" color="text.secondary" paragraph>
                        {product.remarks && product.remarks.length > 0 ? (
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                {product.remarks.map((remark, index) => (
                                    <li key={index}>{remark}</li>
                                ))}
                                {product.classification && <li>{product.classification}</li>}
                            </ul>
                        ) : (
                            'No description available.'
                        )}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" gutterBottom>
                            {new Intl.NumberFormat('en-PK', {
                                style: 'currency',
                                currency: 'PKR',
                                minimumFractionDigits: 0,
                            }).format(product.sale_price || 0)}
                        </Typography>
                        {(product.current_stock || 0) > 0 ? (
                            <Chip   sx={{
                                '.MuiChip-label': {
                                    padding: '12px',
                                },
                            }} label="In Stock" color="success" />
                        ) : (
                            <Chip  sx={{
                                '.MuiChip-label': {
                                    padding: '12px',
                                },
                            }} label="Out of Stock" color="error" />
                        )}
                    </Box>
                    <Box mt={2} display="flex" gap={2} flexWrap="wrap">
                        <Button
                            variant="contained"
                            startIcon={<WhatsAppIcon />}
                            fullWidth
                            onClick={async () => {
                                const link = await createWhatsAppLink(false);
                                window.open(link, '_blank', 'noopener,noreferrer');
                            }}
                        >
                            Contact on WhatsApp
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<ShareIcon />}
                            fullWidth
                            onClick={async () => {
                                const link = await createWhatsAppLink(true);
                                window.open(link, '_blank', 'noopener,noreferrer');
                            }}
                        >
                            Share on WhatsApp
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<PreviewIcon />}
                            fullWidth
                            onClick={handlePreviewOpen}
                        >
                            Preview Brochure
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <StyledTabsContainer>
                <Paper elevation={3}>
                    <StyledTabs
                        value={activeTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Product information tabs"
                    >
                        <StyledTab label="Specifications" id="product-tab-0" aria-controls="product-tabpanel-0" />
                        <StyledTab label="Accessories" id="product-tab-1" aria-controls="product-tabpanel-1" />
                    </StyledTabs>
                </Paper>
            </StyledTabsContainer>

            <Box sx={{ mt: 4 }}>
                <Box ref={specificationsRef} sx={{ mb: 8 }}>
                    <Typography variant="h5" gutterBottom>Specifications</Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        {product.remarks && product.remarks.length > 0 ? (
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                {product.remarks.map((remark, index) => (
                                    <li key={index}>{remark}</li>
                                ))}
                                {product.classification && <li>{product.classification}</li>}
                            </ul>
                        ) : (
                            'Product does not have any specifications.'
                        )}
                    </Typography>
                </Box>

                <Box ref={accessoriesRef} sx={{ mb: 8 }}>
                    <Typography variant="h5" gutterBottom>Accessories</Typography>
                    <Typography variant="body1">With original charger and power cable</Typography>
                </Box>
            </Box>

            <BrochurePreviewModal
                open={isPreviewOpen}
                onClose={handlePreviewClose}
                product={product}
                getImageUrl={getImageUrl}
                onSave={handleSaveBrochure} //Update 4
            />
        </Box>
    );
}

